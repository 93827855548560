/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpalette0: (
  50: #ece5ff,
  100: #d0bfff,
  200: #b195ff,
  300: #926afe,
  400: #7a4afe,
  500: #632afe,
  600: #5b25fe,
  700: #511ffe,
  800: #4719fe,
  900: #350ffd,
  A100: #ffffff,
  A200: #f7f6ff,
  A400: #cac3ff,
  A700: #b4a9ff,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

@import "~@angular/material/theming";
@include mat-core();

$light-app-theme: mat-light-theme((color: (primary: mat-palette($md-mcgpalette0),
        accent: mat-palette($mat-orange, A200, A100, A400),
        warn: mat-palette($mat-red),
      ),
    ));

$dark-app-theme: mat-dark-theme((color: (primary: mat-palette($mat-cyan),
        accent: mat-palette($mat-blue-gray, A200, A100, A400),
        warn: mat-palette($mat-amber),
      ),
    ));

@include angular-material-theme($light-app-theme);

.dark-theme {
  @include angular-material-color($dark-app-theme);
}

/* amplify theme*/
[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: var(--ion-color-primary);
  --amplify-components-button-primary-hover-background-color: var(--ion-color-primary-tint);
  --amplify-components-button-primary-active-background-color: var(--ion-color-primary);

  --amplify-components-button-link-color: var(--ion-color-primary);
  --amplify-components-button-link-hover-color: var(--ion-color-primary-tint);
  --amplify-components-button-link-hover-background-color: var(--ion-color-primary-contrast);
  --amplify-components-button-link-active-color: var(--ion-color-primary);

  --amplify-components-tabs-item-color: var(--ion-color-primary);
  --amplify-components-tabs-item-hover-color: var(--ion-color-primary-tint);
  --amplify-components-tabs-item-active-color: var(--ion-color-primary);
  --amplify-components-tabs-item-focus-color: var(--ion-color-primary);
  --amplify-components-tabs-item-active-border-color: var(--ion-color-primary);
}

@import "~@aws-amplify/ui-angular/theme.css";

/* amplify default style modification*/
.amplify-image {
  max-height: 80px;
}

input[name="unverifiedAttr"] {
  margin-right: 16px;
}

/* ionic default style modification*/
ion-label.error {
    color: red;
}

ion-tab-bar ion-label {
  font-size: 14px;
}

ion-list {
  padding: 0;
  display: inherit;
}

ion-icon {
  pointer-events: none;
}

ion-badge {
  padding: 6px;
}

.md {
  ion-header ion-toolbar ion-title {
    padding-left: 16px;
    line-height: 22px;
  }
}


@media only screen and (min-width: 768px) {
  .finished_single {

    &::part(content) {
      height: 180px;
    }
  }

  .finished_bulk {

    &::part(content) {
      height: 370px;
    }
  }
}



// ::-webkit-scrollbar {
//   height: 12px;
//   width: 12px;
//   background: red;
// }

// ::-webkit-scrollbar-thumb {
//   background: blue;
//   -webkit-border-radius: 1ex;
//   -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
// }

// ::-webkit-scrollbar-corner {
//   background: green;
// }

/* material default style modification */
.mat-tooltip {
  font-size: 14px;
}

/* extension style*/
.customized-modal {
  --height: 420px;
}

.customized-medium-modal {
  --height: 600px;
  --width: 900px;
}

.customized-modal-oneliner {
  --height: 200px;
}

/* global modal css */
.app-modal-window-header {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.font-small{
  font-size: 1rem;
}

.color-red{
  color: red;
}

.alert-custom-class {
  .alert-wrapper{
    min-width: 400px;
  }
  .alert-message{
    color: red;
  }
}